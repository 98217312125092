const humanize = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return str;
}

export const StringUtil = {
  nameFormatter(str) {
    if (str) {
      let i, frags = str.split(' ');
      for (i = 0; i < frags.length; i++) {
        frags[i] = humanize(frags[i]);
      }
      return frags.join(' ');
    }
    return str;

  },
  categoryFormatter(str) {
    let i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = humanize(frags[i]);
    }
    return frags.join(' ');
  },
  truncate(str, n) {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
  },
  isValidHttpUrl(val) {
    let url;

    try {
      url = new URL(val);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  },
}